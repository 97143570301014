// Colors

$white: rgb(255, 255, 254);
$white_for_dark_mode: #c7c1c0;
$link_for_dark_mode: #fbfafa;
$read_more: #241c1c;
$read_more_for_dark: #d6cdcc;
$light_white: #ccc3c2;
$highlight: #f2eae8;
$highlight_for_dark: #332f2e;
$link_hover_on_light: #f4e5e5;
$highlighted_link_on_light: #a0031e;
$highlighted_visited_link_on_light: #544c4b;
$highlighted_link_on_dark: #fffefe;
$highlighted_visited_link_on_dark: #d5b7ac;
$highlighted_text_on_dark: #d7d0ce;
$black: #292222;
$codgraydark: #101010;
$sharkdark: #19191a;
$codgray: #181818;
$shark: #212123;
$mineshaft: #282828;
$mineshaftLight: #2d2d2d;
$tuna: #323234;
$lightTuna: #363638;
$satan: #5b5352;
$dustygray: #999999;
$alto: #d8d8d8;
$gallerydarken: #f0f0f0;
$wildsand: #f5f5f5;
$alabaster: #fcfcfc;
$gallery: #efefef;
$monza: #ae0d21;
$link_hover: #ae0d21;
$link_visited: #5b5352;
$link_visited_for_dark: #bf9f97;
$shiraz: #c3102d;
//$radicalred: #FB3B5B;
$radicalred: #da1434;
$dark_link_hover: #b55262;
$chateaugreen: #46b864;
$dark_black: #1c1b1b;
$lightWhite: #e2dbd9;
$lightwhiteborder: #fbfafa;
$yellow: #ffd707;
$border_light: #8c8180;
$border_dark: #47403e;
$black10: rgba($black, 0.1);
$white25: rgba($white, 0.25);
$facebook: #3a5798;
$messenger: #00b2ff;
$twitter: #1da1f2;
$x-twitter: #000;
$whatsapp: #29b200;
$gmail: #ea4335;
$DarkTheme_menu_bcg: #1c1b1b;
$DarkTheme_serch_box: #2d2d2e;
$link_light_underline: rgba(91, 83, 82, 0.66);
$link_dark_underline: rgba(214, 205, 204, 0.49);

// Fonts
$font-family-primary: 'Lumin', serif;
$font-family-secondary: 'Inter', sans-serif;
$font-size-xl: 17px;
$font-size-sm: 14px;
$font-size-base: 16px;
$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-normal: 500;
$font-weight-light: 300;

$font-size-xxl: 30px;
$font-size-xxl-small: 24px;
$font-size-huge: 22px;
$font-size-large: 21px;
$font-size-medium: 19px;
$font-size-normal: 19px;
$font-size-semi: 15px;
$font-size-small: 12px;

$line-height-42: 42px;
$line-height-31: 31px;
$line-height-30: 30px;
$line-height-26: 26px;
$line-height-24: 24px;
$line-height-23: 23px;
$line-height-22: 22px;
$line-height-20: 20px;
$line-height-19: 19px;
$line-height-18: 18px;
$line-height-17: 17px;

$topOffset: 48px;

$namespace: 'mnt';

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1580px,
);
