.mnt-PendingPostsCTA {
  position: absolute;
  height: 0;
  transform: translate(-50%, -10px);
  z-index: 1002;
  left: 50%;
  width: 160px;
  button {
    white-space: nowrap;
    cursor: pointer;
    padding: 11px 15px;
    font-family: $font-family-secondary;
    color: $lightwhiteborder;
    font-weight: 600;
    font-size: $font-size-small;
    line-height: 1.2em;
    background-color: $monza;
    border-radius: 22px;
    border: none;
    text-transform: uppercase;
    svg {
      margin-right: 8px;
      width: 10.5px;
      height: 10.5px;
      fill: rgba($white, 0.6);
    }
  }
}
