.mnt-Search {
  border: 1px solid $lightWhite;

  .mnt-border_bottom {
    border-bottom: 1px solid $lightWhite;
  }

  header {
    padding: 24px;
  }
  h1 {
    font-size: $font-size-xxl-small;
    line-height: $line-height-26;
  }

  h3 {
    text-align: center;
    padding: 21px 21px 25px 21px;
  }
  article h3 {
    text-align: left;
    padding: 0;
  }
}
