input[type='text'] {
  width: 100%;
  background-color: $wildsand;
  padding: 10px 15px;
  border-radius: 3px;
  border: none;
  height: 40px;
  @include media-breakpoint-up(md) {
    height: 50px;
  }
  &::placeholder {
    font-family: $font-family-primary;
    color: rgba($black, 0.5);
    font-size: $font-size-normal;
    line-height: $line-height-23;
    font-weight: 300;
  }
  &:focus {
    outline-color: rgba($dustygray, 0.5);
  }
}
.mnt-Switcher {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .mnt-label {
    white-space: nowrap;
    margin-left: 8px;
    left: 0;
    position: relative;
    color: $border_light;
    text-transform: none;
    font-size: 16px;
  }
  .mnt-btnGroup & {
    height: 63px;
    align-items: center;
    justify-content: left;
    &:first-child {
      border-style: solid;
      border-radius: 0 0 0 0;
    }
    &:last-child {
      border-style: solid none solid none;
      border-radius: 0 0 0 0;
    }
    @include media-breakpoint-up(sm) {
      height: 45px;
      &:first-child {
        border-radius: 0 0 0 0;
        border-style: solid none none none;
      }
      &:last-child {
        border-radius: 0 0 0 0;
        border-style: solid none none solid;
      }
    }
    &:last-child {
      border-radius: 0 0 0 0;
    }
  }
}
.mnt-Switch {
  font-family: $font-family-secondary;
  font-size: 13px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked {
      + .mnt-slider {
        background-color: $monza;
        &::before {
          transform: translateX(16px);
        }
        + .mnt-label {
          color: $monza;
        }
      }
    }
  }
  .mnt-slider {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    background-color: $dustygray;
    transition: 0.4s;
    border-radius: 80px;
    &::before {
      position: absolute;
      border-radius: 50%;
      content: '';
      height: 16px;
      width: 16px;
      left: 4px;
      bottom: 4px;
      background-color: $white;
      transition: 0.3s ease 0s;
    }
  }
}
.mnt-ToolbarSerchBox {
  margin-left: 10px;
  position: relative;
  input[type='text'] {
    padding: 11px 27px 11px 11px;
    width: 0;
    height: 42px;
    background-color: $black;
    border: 1px solid rgba($white, 0.25);
    border-radius: 3px;
    transition: width 0.25s ease 0s;
    color: $white;
    font-family: $font-family-primary;
    font-weight: $font-weight-medium;
    font-size: $font-size-base;
    &::placeholder {
      font-size: $font-size-base;
      color: rgba($white, 0.4);
    }
    &:active,
    &:target,
    &:focus {
      width: 260px;
      background-color: rgba($white, 0.1);
      outline-style: none;
      & + .mnt-searchBtn {
        background-color: $monza;
        border-color: $monza;
      }
    }
  }
  .mnt-searchBtn {
    border: 1px solid rgba($white, 0.25);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 40px;
    height: 42px;
    background-color: $black;
    border-radius: 3px;
    top: 0;
    right: 0;
    transition: background-color 0.25s ease 0s;
    svg {
      width: 15px;
      height: 15px;
      fill: $white;
    }
  }
}
