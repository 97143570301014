%content-padding {
  padding: 24px;
  max-width: 100vw;
}

.mnt-FeedSticky {
  position: relative;
  border: 1px solid $lightWhite;

  .mnt-border_bottom {
    border-bottom: 1px solid $lightWhite;
  }

  header {
    padding: 24px;

    h1 {
      color: $black;
      font-weight: $font-weight-medium;
      font-size: $font-size-xxl-small;
      line-height: $line-height-26;
      font-family: $font-family-primary;
    }
  }

  article {
    .mnt-header-excerpt:last-child {
      padding-bottom: 12px;
    }
    .mnt-header-excerpt,
    .mnt-articles-about {
      @extend %content-padding;
      //border: 1px solid $lightWhite;
      font-weight: $font-weight-light;
      line-height: 24px;
      &:last-child {
        margin-bottom: 0;
      }
      strong,
      b {
        font-weight: $font-weight-medium;
      }
      p,
      ul {
        margin-bottom: 15px;
        margin-top: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        &:only-child,
        &:first-child {
          margin-top: 0;
        }
      }
    }
    ul {
      padding-right: 20px;
      li {
        font-size: $font-size-normal;
        font-weight: $font-weight-light;
        font-family: $font-family-primary;
        line-height: $line-height-24;
        padding: 2px 0;
        margin-left: 20px;
        position: relative;
        list-style: square;
        list-style-position: outside;
        &::marker {
          color: $highlighted_link_on_light;
        }
        strong,
        b,
        a {
          font-weight: $font-weight-medium;
        }
        a:hover {
          text-decoration: underline;
        }
        &:last-child {
          padding-bottom: 0;
        }
        @include media-breakpoint-up(md) {
          font-size: 19px;
        }
      }
    }

    a {
      color: $monza;
    }

    div > strong {
      display: inline-block;
    }

    .mnt-articles-about {
      background: $highlight;
      border-top: 1px solid #e2dbd9;
      border-bottom: 1px solid #e2dbd9;
    }
  }

  article:not(:last-child) {
    border-bottom: 1px solid rgba(#181818, 0.07);
  }

  .mnt-adminEdit {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $border_light;
    width: 23px;
    height: 23px;
    border-radius: 12px;
  }
  .mnt-header-excerpt {
    background-color: $highlight;
  }
}
