%colorWhite {
  color: $white_for_dark_mode;
}

.mnt-darkTheme {
  #mpm_top,
  #mpm_middle {
    border-color: $border_dark;
  }

  .mnt-Minuta {
    background-color: $dark_black;
    color: $white_for_dark_mode;
  }

  .mnt-FeedArticle.mnt-FeedArticleImportant {
    background-color: $highlight_for_dark;
    .mnt-tags.mnt-fade-end::after {
      background: linear-gradient(to right, rgba($shark, 0), $highlight_for_dark);
    }
  }

  a {
    @extend %colorWhite;
  }

  .mnt-layout__menu {
    .mnt-tags.mnt-fade-end::after {
      background: linear-gradient(to right, rgba($codgraydark, 0), $codgraydark);
    }
  }

  .mnt-carret-right {
    &::after {
      background: linear-gradient(to right, rgba(#1c1b1b, 0), #1c1b1b);
    }
  }

  .mnt-carret-left {
    &::after {
      background: linear-gradient(to left, rgba(#1c1b1b, 0), #1c1b1b);
    }
  }

  .mnt-settings .mnt-newsletter,
  .mnt-sideMenu a,
  .mnt-sideMenu .mnt-IconLink {
    @extend %colorWhite;

    &:hover {
      background-color: rgba($mineshaft, 0.8);
    }
  }

  .mnt-settings .mnt-newsletter svg,
  .mnt-sideMenu .mnt-IconLink svg {
    fill: $radicalred;
  }

  .mnt-Menu h5 {
    color: $border_light;
  }

  .mnt-Menu .mnt-LiveLink {
    a {
      @extend %colorWhite;

      &.active,
      &:hover {
        color: rgba($lightwhiteborder, 0.8);
        //color: $radicalred;
      }
    }
  }

  .mnt-Menu .mnt-tags {
    border-color: $border_dark;
  }

  .mnt-Menu .mnt-LiveLink,
  .mnt-Menu .mnt-LiveLinks,
  .mnt-sideNav,
  .mnt-sideMenu .mnt-IconLink:last-child {
    border-color: $border_dark;
  }

  .mnt-sideMenu.mnt-bordered_bottom {
    border-color: $border_dark;
  }

  .mnt-sideMenu {
    .mnt-IconLink {
      &.active {
        color: $lightwhiteborder;
        background-color: $mineshaftLight;
      }
    }

    .mnt-NavLink {
      &.active {
        color: $lightwhiteborder;
        background-color: $mineshaftLight;
      }
    }
  }

  .mnt-Switch {
    input {
      &:checked {
        + .mnt-slider {
          background-color: $radicalred;

          + .mnt-label {
            color: $link_for_dark_mode;
          }
        }

        &:hover {
          + .mnt-slider {
            background-color: $dark_link_hover;

            + .mnt-label {
              color: $link_for_dark_mode;
            }
          }
        }
      }
    }
  }

  input[type='text'] {
    background-color: $dark_black; //#2d2d2e
    color: $light_white;
    border-color: #766d6c;

    &::placeholder {
      color: $light_white;
    }
  }

  .mnt-btnGroup .mnt-btnItem {
    border-color: $mineshaftLight;
    background-color: $codgraydark;
  }

  .mnt-Switcher {
    background-color: $shark;
    border-color: rgba($white_for_dark_mode, 0.25);

    .mnt-label {
      color: $link_for_dark_mode;
    }

    //&:hover .mnt-label {
    //color: rgba($link_for_dark_mode, 0.8);
    //}
  }

  .mnt-live {
    background-color: $black;
    color: $white;

    &::before {
      background-color: $radicalred;
    }
  }
  .mnt-tags a {
    background-color: transparent;
    border-color: rgba($white_for_dark_mode, 0.25);
    color: $white_for_dark_mode;

    &:hover {
      border-color: $border_dark;
    }
  }
  .mnt-MenuSearchBox svg {
    fill: rgba($white_for_dark_mode, 0.5);
  }

  .mnt-Layout {
    .mnt-layout__content .mnt-layout__content-feed {
      border-color: $border_dark;
    }

    .mnt-layout__bottom-toolbar-cz .mnt-logoMnt {
      background-color: $shark;
    }

    .mnt-overlay__menu {
      background-color: rgba($white_for_dark_mode, 0.6);
    }
  }

  .mnt-FeedArticleImportant,
  .mnt-articleLive {
    background-color: $highlight_for_dark;
  }
  .mnt-FeedSticky {
    background-color: $dark_black;
    article ul li {
      &::marker {
        color: $highlighted_link_on_dark;
      }
    }

    .mnt-adminEdit {
      svg {
        fill: $white_for_dark_mode;
      }
    }

    .mnt-tags.mnt-fade-end::after {
      background: linear-gradient(to right, rgba($DarkTheme_serch_box, 0), $DarkTheme_serch_box);
    }

    header {
      border-color: $border_dark;

      h1 {
        color: $white_for_dark_mode;
      }
    }
    &.mnt-border_bottom {
      border-color: $border_dark;
    }

    .mnt-border_bottom {
      border-color: $border_dark;
    }

    a {
      color: $link_for_dark_mode;

      //:hover {
      //color: rgba($link_for_dark_mode, 0.8);
      //}

      :visited {
        color: $link_visited_for_dark;
      }
    }

    a.mnt-tag {
      color: $read_more_for_dark;

      &:hover {
        border-color: $read_more_for_dark;
      }
    }

    article {
      .mnt-articles-about {
        color: $link_for_dark_mode;
        background: #322f2f;
        border-color: $border_dark;
        //a:hover {
        //color: rgba($link_for_dark_mode, 0.8);
        //}
      }
      &:not(:last-child) {
        border-color: $border_dark;
      }
    }
  }

  .mnt-FeedArticleImportant,
  .mnt-articleLive {
    background-color: $highlight_for_dark;
  }
  .mnt-articleLive {
    .mnt-liveLink {
      color: $link_for_dark_mode;
    }
    //.mnt-liveLink:hover {
    //  color: rgba($link_for_dark_mode, 0.8);
    //}
  }

  .mnt-FeedSticky article ul li:last-child a {
    background-color: transparent;
    border-color: $border_dark;

    //:hover {
    //  color: rgba($link_for_dark_mode, 0.8);
    //}

    :visited {
      color: $link_visited_for_dark;
    }
  }

  .mnt-Search {
    border-color: $border_dark;
    .mnt-border_bottom {
      border-color: $border_dark;
    }
    h1 {
      background: $DarkTheme_menu_bcg;
    }
  }

  .mnt-FeedArticle {
    background: $DarkTheme_menu_bcg;
    color: $white_for_dark_mode;
    border-color: $border_dark;

    & strong {
      color: $white_for_dark_mode;
    }

    .mnt-tags.mnt-fade-end::after {
      background: linear-gradient(to right, rgba($DarkTheme_menu_bcg, 0), $DarkTheme_menu_bcg);
    }

    .mnt-article {
      ul li {
        &::marker {
          color: $lightwhiteborder;
        }
        a {
          &:hover {
            background-color: $border_dark;
          }
        }
      }
      & > article,
      & > p {
        a {
          color: $link_for_dark_mode;
          text-decoration-color: $link_dark_underline;

          &:active,
          &:hover,
          &:focus {
            color: $link_for_dark_mode !important;
            background-color: $border_dark;
          }
          &[data-link='ext']:hover::after,
          &[data-link='ext']:focus::after {
            background-color: $link_for_dark_mode !important;
          }

          &[data-link='ext']:visited::after {
            background-color: $link_visited_for_dark;
          }
        }
      }
    }

    .mnt-article ul li a,
    .mnt-article > p a,
    .mnt-article > article a {
      color: $link_for_dark_mode;

      //&:hover,
      //&:active {
      //color: rgba($link_for_dark_mode, 0.8);
      //}

      &[data-link='ext']::after {
        background-color: $link_for_dark_mode;
      }

      &:visited {
        color: $link_visited_for_dark;
      }

      //&[data-link='ext']:hover::after {
      //  background-color: $link_visited_for_dark;
      //}

      //&[data-link='ext']:visited::after {
      //  background-color: $link_visited_for_dark;
      //}
    }

    .tile {
      background-color: $shark;
      a {
        color: $link_for_dark_mode !important;
      }
    }

    .tile_call {
      color: rgba($white_for_dark_mode, 0.5);
    }

    figure figcaption {
      color: $read_more_for_dark;
    }
  }

  .mnt-PendingPostsCTA button {
    background-color: $radicalred;
  }

  .mnt-btn.mnt-pink {
    background-color: rgba($radicalred, 0.2);
    border-color: transparent;
    color: $radicalred;
  }
  .mnt-btn.mnt-btn-red {
    background-color: $radicalred;
  }

  .mnt-ToolbarDesk {
    .mnt-loader-sk {
      background-color: $monza;

      &::before {
        background: $monza url('../../assets/sk/svg/loading_bcg_light.svg') repeat-y scroll center 0/47px auto;
      }

      &::after {
        background: transparent url('../../assets/sk/svg/loading_after_light.svg') no-repeat scroll center 0/47px auto;
      }
    }
    .mnt-loader-cz {
      background-color: $white_for_dark_mode;
    }
  }

  .mnt-ToolbarMob {
    background-color: $dark_black;
    border-bottom: 1px solid $lightTuna;

    .mnt-loader-cz {
      background-color: $white_for_dark_mode;
    }
  }
  .mnt-widgetTopbar .mnt-dotsMenu svg {
    fill: $light_white;
  }
  .mnt-widgetTopbar .mnt-widgetExternalLink svg {
    fill: $light_white;
  }
  .mnt-widgetTopbar .mnt-TopbarLogo span {
    color: $white_for_dark_mode;
  }
  .mnt-logoMnt {
    .mnt-TopbarLogo span,
    .mnt-logoTitle {
      color: $lightwhiteborder;
    }

    .mnt-logo {
      &::after {
        background-color: $radicalred;
      }
    }
  }

  .mnt-FeedArticle .mnt-toolbar .mnt-time {
    color: $white_for_dark_mode;
  }

  .mnt-FeedArticle .mnt-toolbar a:hover .mnt-time {
    color: rgba($white_for_dark_mode, 0.8);
  }

  .mnt-FeedArticle .mnt-toolbar svg {
    fill: $white_for_dark_mode;
  }

  .mnt-FeedArticle .mnt-toolbar .mnt-External:hover svg {
    fill: rgba($white_for_dark_mode, 0.8);
  }

  .mnt-FeedArticle .mnt-toolbar .mnt-Icons .mnt-External {
    border-color: #766d6c;
    background: transparent;

    &:hover {
      background-color: $read_more_for_dark;
      svg {
        fill: $border_dark;
      }
    }

    &:focus {
      background-color: $border_dark;
      svg {
        fill: $read_more_for_dark;
      }
    }

    svg {
      fill: $read_more_for_dark;
    }
  }

  .mnt-Menu {
    background-color: $DarkTheme_menu_bcg; //#1e1e1f
    border-color: $border_dark;
  }

  .mnt-FeedSticky article ul li {
    color: $white_for_dark_mode;
  }

  .mnt-backLink {
    svg {
      fill: $white_for_dark_mode;
    }

    span {
      color: $white_for_dark_mode;
    }
  }

  .mnt-ModalWrapper {
    background-color: rgba($black, 0.8);
  }

  .mnt-ModalWrapper .mnt-ModalContent {
    background-color: $codgray;

    h1,
    p {
      color: $white_for_dark_mode;
    }

    h3 {
      color: rgba($white_for_dark_mode, 0.5);
    }

    p {
      a {
        color: $link_for_dark_mode;
        font-weight: $font-weight-medium;

        //&:hover {
        //color: rgba($link_for_dark_mode, 0.8);
        //}
      }
    }
  }

  .mnt-shareLinks {
    background: $dark_black;
    border-color: $border_dark;

    .mnt-claerShareLinks:hover svg {
      fill: $radicalred;
    }

    .mnt-shares {
      button,
      a {
        background-color: transparent;
        border-color: $border_dark;
        color: $light_white;

        &:hover,
        .mnt-highlight {
          color: $radicalred;

          svg {
            fill: $radicalred;
          }
        }
        svg {
          fill: $border_dark;
        }
      }
    }
  }

  .mnt-readMore {
    color: $read_more_for_dark;
    border: none;
    svg {
      fill: $read_more_for_dark;
    }
  }

  .mnt-widgetSettings {
    background-color: $shark;

    .mnt-widgetSettings-header p {
      color: $white_for_dark_mode;
    }

    .mnt-widgetSettings-caption {
      color: $white_for_dark_mode;
    }

    .mnt-widgetSettings-header {
      span {
        svg {
          fill: $white_for_dark_mode;
        }
      }
    }

    .mnt-widgetSettings-selector {
      button {
        background-color: #090808;
        color: $light_white;
        border: 1px solid rgba(255, 255, 255, 0.15);

        &.active {
          background-color: rgba($white_for_dark_mode, 0.95);
          color: rgba($black, 1);
        }
      }
    }

    .mnt-widgetSettings-description {
      color: $dustygray;
      border-bottom: 1px solid $border_dark;
    }

    .mnt-label.active {
      color: $radicalred;
    }
  }

  .mnt-viewOnMnt {
    background: $dark_black;
    color: $link_for_dark_mode;
    border-color: $border_dark;

    &::after {
      background-color: $link_for_dark_mode;
    }
  }

  .mnt-loadMoreOverlay {
    background: $codgray;
    background: linear-gradient(0deg, rgba($codgray, 1) 40%, rgba($codgray, 0) 100%);
  }

  .mnt-articleLive {
    border-color: $border_dark;
  }

  .mnt-articleLive .mnt-time {
    color: $white_for_dark_mode;
  }

  .mnt-articleLive .mnt-liveShare-wrapper .mnt-live-header .mnt-time {
    color: $white_for_dark_mode;
  }

  .mnt-articleLive .mnt-liveShare-wrapper .mnt-External svg {
    fill: $white_for_dark_mode;
  }

  .mnt-articleLive .mnt-liveShare-wrapper .mnt-External:hover svg {
    fill: rgba($white_for_dark_mode, 0.8);
  }

  .mnt-LiveHeaderAuthorShare {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;

    .mnt-External {
      svg {
        fill: $link_for_dark_mode;
      }

      //&:hover {
      //color: $dark_link_hover;

      //svg {
      //  fill: $link_for_dark_mode;
      //}
      //}
    }
  }

  p.mnt-liveAuthor {
    color: $white_for_dark_mode;
  }

  .mnt-articleCurrentlyLive {
    background-color: $DarkTheme_serch_box;
  }

  .mnt-articleArchivedLive {
    background-color: $DarkTheme_menu_bcg;
  }

  .mnt-widgetTopbar-fixed {
    .tile_title a {
      color: $white_for_dark_mode !important;
    }
  }

  .mnt-AsideHeader {
    background: $dark_black;
    h3 {
      color: $white_for_dark_mode;
    }
  }
  .mnt-postBlock {
    &::after {
      background: linear-gradient(transparent, rgba(0, 0, 0, 0.6));
    }
  }

  .mnt-Aside {
    border-color: $border_dark;
    .mnt-TrendingPost,
    .mnt-CurrentPost,
    .mnt-AsideHeader {
      border-color: $border_dark;
      color: $link_for_dark_mode;
    }
  }
  .mnt-tags {
    .mnt-tag {
      border-color: #766d6c;
      &.mnt-highlight {
        background-color: $link_for_dark_mode;
        color: $black;
        //text-decoration: underline;
        //text-decoration-color: $link_dark_underline;
      }
      &:hover {
        border-color: $read_more_for_dark;
      }
    }
  }
  .mnt-Aside .mnt-CurrentPosts {
    scrollbar-color: $border_dark transparent;
    &:hover {
      scrollbar-color: $border_dark transparent;
    }
  }
  .mnt-Posts,
  .mnt-FeedSticky article .mnt-header-excerpt {
    border-color: $border_dark;
  }
  .mnt-header-excerpt {
    background-color: $highlight_for_dark;
  }

  .tile_title {
    a {
      &:hover {
        background-color: transparent !important;
      }
    }
  }

  .mnt-FeedArticleSeen .mnt-toolbar .mnt-time::after {
    background-color: $link_for_dark_mode;
  }

  .mnt-FeedArticle.mnt-FeedArticleImportant {
    color: $highlighted_text_on_dark;
    &.mnt-FeedArticleSeen {
      .mnt-toolbar {
        .mnt-time {
          &::after {
            background-color: $highlighted_link_on_dark;
          }
        }
      }
    }
    .mnt-article {
      ul li {
        &::marker {
          color: $highlighted_link_on_dark;
        }
      }
      & > p {
        a {
          color: $highlighted_link_on_dark;
          &:active,
          &:hover,
          &:focus {
            color: $highlighted_link_on_dark !important;
            background-color: $border_dark;
          }

          &:visited {
            color: $highlighted_visited_link_on_dark;
          }

          &[data-link='ext']:hover::after,
          &[data-link='ext']:focus::after {
            background-color: $highlighted_link_on_dark !important;
          }
          &[data-link='ext']::after {
            background-color: $highlighted_link_on_dark;
          }
          &[data-link='ext']:visited::after {
            background-color: $highlighted_visited_link_on_dark;
          }
        }
      }
    }
  }
  .mnt-btn.mnt-btn-small {
    background-color: transparent;
    border-color: #766d6c;
    color: $white_for_dark_mode;
    &:hover {
      border-color: $read_more_for_dark;
    }
    svg {
      fill: $white_for_dark_mode;
    }
  }
}
