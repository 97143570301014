%radio_checkbox {
  visibility: hidden;
  width: 0;
  height: 0;
}
%radio_checkbox_design {
  transition: all 0.1s linear 0s;
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  border: 1px solid $alto;
}
%radio_checkbox_design_checked {
  border: 5px solid $monza;
}
.mnt-ModalWrapper {
  position: fixed;
  z-index: 2002;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($black, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  .mnt-Modal {
    margin: auto;
    @include media-breakpoint-down(sm) {
      max-height: 90vh;
      overflow-x: auto;
    }
  }
  .mnt-ModalContent {
    width: 90vw;
    background-color: $white;
    padding: 20px 20px;
    @include media-breakpoint-up(sm) {
      width: 513px;
      padding: 35px;
      border-radius: 3px;
    }
    h1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $black;
      font-size: 25px;
      line-height: 35px;
      margin-bottom: 15px;
      @include media-breakpoint-up(sm) {
        font-size: $font-size-xxl;
        font-weight: $font-weight-medium;
        line-height: $line-height-42;
        margin-bottom: 5px;
      }
      .mnt-ModalClear {
        svg {
          width: 16px;
          height: 16px;
          fill: rgba($codgray, 0.4);
        }
        &:hover {
          svg {
            fill: rgba($monza, 0.6);
          }
        }
      }
    }
    p {
      font-size: $font-size-base;
      font-weight: $font-weight-light;
      line-height: $line-height-26;
      a {
        color: $monza;
        font-size: inherit;
      }
    }
    h3 {
      color: rgba($black, 0.4);
      font-size: $font-size-sm;
      font-family: $font-family-secondary;
      font-weight: $font-weight-medium;
      letter-spacing: 0.41px;
      line-height: $line-height-18;
      text-transform: uppercase;
      margin: 20px 0 15px;
      @include media-breakpoint-up(sm) {
        margin: 30px 0 17px;
      }
    }
    .mnt-listModal {
      p {
        display: flex;
        align-items: flex-start;
        color: $black;
        line-height: $line-height-23;
        font-weight: $font-weight-medium;
        @include media-breakpoint-up(sm) {
          align-items: center;
        }
        &:not(:last-child) {
          margin-bottom: 15px;
        }
        svg {
          position: relative;
          top: 3px;
          fill: $monza;
          width: 18px;
          height: 18px;
          margin-right: 13px;
          @include media-breakpoint-up(sm) {
            top: 0;
          }
        }
      }
    }
    form {
      &.mnt-loginForm {
        padding: 25px 0 0 0;
        @include media-breakpoint-up(sm) {
          padding: 35px 0 0 0;
        }
      }
      label {
        cursor: pointer;
      }
      .mnt-fields {
        display: grid;
        grid-column-gap: 21px;
        grid-template-columns: 138px 138px;
        grid-template-rows: auto;
        @include media-breakpoint-up(sm) {
          grid-template-columns: 211px 211px;
        }
        svg {
          height: 82px;
          width: 100%;
          @include media-breakpoint-up(sm) {
            height: 123px;
          }
        }
        input[type='radio'] {
          @extend %radio_checkbox;
          + span::before {
            @extend %radio_checkbox_design;
            width: 18px;
            height: 18px;
            border-radius: 9px;
          }
          + span small {
            font-size: inherit;
            font-weight: $font-weight-light;
          }
        }
        input[type='radio']:checked {
          + span::before {
            @extend %radio_checkbox_design_checked;
          }
        }
        input[type='checkbox'] {
          @extend %radio_checkbox;
          + span {
            font-family: $font-family-secondary;
            font-size: 16px;
            padding-left: 35px;
          }
          + span::before {
            @extend %radio_checkbox_design;
            width: 25px;
            height: 25px;
            border-radius: 2px;
          }
          &:checked {
            + span::before {
              content: '✔';
              text-align: center;
              font-size: 28px;
              color: $white;
              background-color: $chateaugreen;
            }
          }
        }
        span {
          position: relative;
          padding-left: 28px;
          font-weight: $font-weight-medium;
          line-height: $line-height-23;
        }
      }
      .mnt-Switcher,
      .mnt-field {
        position: relative;
        + .mnt-fields {
          padding-top: 5px;
        }
        + .mnt-actions {
          padding-top: 5px;
          @include media-breakpoint-up(sm) {
            padding-top: 30px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 15px;
        }
        .mnt-showPwd {
          position: absolute;
          right: 15px;
          width: 18px;
          height: 18px;
          top: 50%;
          transform: translate(0, -50%);
          display: flex;
          align-items: center;
          justify-content: center;
        }
        svg {
          width: 18px;
          height: 18px;
          fill: rgba($codgray, 0.4);
        }
        input[type='e-mail'],
        input[type='password'] {
          height: 40px;
          padding: 10px 15px;
          width: 100%;
          border: none;
          border-radius: 3px;
          background-color: $wildsand;
          font-size: $font-size-base;
          color: rgba($black, 0.5);
          font-family: $font-family-secondary;
          font-weight: $font-weight-light;
          &:focus {
            outline-style: none;
          }
          &::placeholder {
            color: rgba($black, 0);
          }
          &:placeholder-shown {
            & + label {
              top: 50%;
              left: 15px;
              transform: translate3d(0, -50%, 0) scale(1.5);
              font-weight: $font-weight-light;
              line-height: $line-height-23;
            }
          }
        }
        input[type='password'] {
          padding-right: 35px;
        }
        label {
          color: rgba($black, 0.5);
          font-size: 12px;
          transition: all 0.25s ease 0s;
          position: absolute;
          top: -13px;
          left: 0;
          font-weight: $font-weight-light;
          transform-origin: center left;
          pointer-events: none;
        }
      }
      .mnt-Switcher {
        display: flex;
        position: static;
        top: 0;
        align-items: flex-start;
        justify-content: space-between;
        @include media-breakpoint-up(xs) {
          align-items: center;
        }
        > span {
          width: #{'calc(100% - 37px)'};
          color: $black;
          line-height: $line-height-23;
          font-size: $font-size-base;
          font-weight: $font-weight-medium;
        }
      }
    }
    .mnt-forgotenPwd {
      color: $monza;
      font-family: $font-family-secondary;
      font-size: $font-size-sm;
      letter-spacing: 0.28px;
      line-height: $line-height-18;
      text-transform: uppercase;
      text-align: center;
      margin: 15px 0 0 0;
      @include media-breakpoint-up(sm) {
        text-align: right;
        margin-top: 0;
      }
    }
    &.mnt-login {
      @include media-breakpoint-up(sm) {
        width: 413px;
      }
      .mnt-fields {
        grid-template-columns: auto;
        justify-content: center;
        grid-column-gap: 0;
        @include media-breakpoint-up(sm) {
          grid-template-columns: 176px auto;
          justify-content: space-between;
        }
      }
    }
    .mnt-actions {
      padding: 25px 0 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include media-breakpoint-up(sm) {
        padding: 35px 0 0 0;
      }
      .mnt-btn {
        width: 100%;
        background-color: $monza;
        @include media-breakpoint-up(sm) {
          width: 200px;
        }
      }
      p {
        font-family: $font-family-secondary;
        font-weight: $font-weight-light;
        line-height: $line-height-26;
      }
      a {
        color: $monza;
        font-family: $font-family-secondary;
        font-size: $font-size-sm;
        letter-spacing: 0.28px;
        line-height: $line-height-18;
        text-transform: uppercase;
      }
    }
  }
}
