.mnt-btnGroup {
  display: flex;
  align-items: center;
  padding-top: 5px;
  .mnt-settings & {
    display: grid;
    grid-template-columns: 45% 55%;
  }
  .mnt-btnItem {
    height: 45px;
    border-color: $gallery;
    border-width: 1px;
    border-style: solid solid solid none;
    background-color: $white;
    transition: background-color 0.25s ease 0s;
    display: flex;
    width: 100%;
    &:hover {
      background-color: $wildsand;
    }
    &:first-child {
      border-style: solid;
      border-radius: 6px 0 0 6px;
    }
    &:last-child {
      border-radius: 0 6px 6px 0;
    }
  }
  .mnt-settings & {
    margin: 0;
    @include media-breakpoint-down(sm) {
      .mnt-btnItem {
        padding-left: 0;
        padding-right: 0;
        border-radius: 0;
        justify-content: center;
        width: 50%;
        display: flex;
      }
    }
  }
}
.mnt-btn {
  white-space: nowrap;
  padding: 10px 16px;
  background-color: $black;
  color: $white;
  font-family: $font-family-secondary;
  font-weight: $font-weight-medium;
  font-size: $font-size-sm;
  line-height: $line-height-18;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba($white, 0.25);
  border-radius: 80px;
  text-transform: uppercase;
  cursor: pointer;
  span {
    padding: 0 8px;
    @include media-breakpoint-up(xl) {
      display: block;
    }
  }
  svg {
    width: 15px;
    height: 15px;
    fill: $white;
    &.mnt-carret {
      fill: $white !important;
      fill-opacity: 0.5;
      width: 13px;
      height: 13px;
    }
  }
  + .mnt-btn {
    margin-left: 10px;
  }
  &.mnt-green {
    background-color: $chateaugreen;
    border-color: $chateaugreen;
  }
  &.mnt-yellow {
    background-color: $yellow;
    border-color: $yellow;
    color: $dark_black;
    text-transform: none;
    font-size: 16px;
    padding: 7px 16px;
    transition: 200ms;
    &:hover {
      background-color: #e4bb59;
    }
    &:focus {
      background-color: $read_more_for_dark;
      color: $dark_black;
      border-color: $read_more_for_dark;
    }
  }
  &.mnt-anonym {
    color: $radicalred;
    svg {
      fill: $radicalred;
    }
  }
  &.mnt-loged {
    svg {
      fill: $chateaugreen;
    }
  }
  &.mnt-pink {
    background-color: rgba($monza, 0.08);
    color: $monza;
  }
  &.mnt-highlight {
    border-color: $alto;
    background-color: $white;
    color: $monza;
    svg {
      fill: $monza;
    }
  }
  &.mnt-btn-small {
    padding: 10px 16px;
    margin: 5px 0 16px;
    font-size: 16px;
    max-width: max-content;
    font-weight: 500;
    color: #111010;
    text-transform: none;
    border-color: #c2bbb9;
    &:hover {
      border-color: $border_dark;
    }
    span {
      padding-right: 0;
    }
    .mnt-ExternalIcon {
      padding-left: 3px;
    }
    svg.mnt-ExternalIcon {
      display: none;
    }
    svg {
      width: 10px;
      fill: $black;
    }
  }
  &.mnt-btn-wider {
    padding: 12px 20px;
  }
  &.mnt-readMore {
    display: inline-block;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
    color: $read_more;
    font-family: $font-family-secondary;
    font-size: 14px;
    line-height: 18px;
    font-weight: $font-weight-bold;
    margin: 0 0 8px;
    text-transform: uppercase;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 18px;
      border-bottom: 1px solid $border_light;
      padding: 2px;
      bottom: 1px;
      background-color: transparent;
      pointer-events: none;
    }
    svg {
      fill: $read_more;
      width: 9px;
      margin-left: 8px;
      padding-top: 4px;
    }
  }
  &.mnt-btn-sharp {
    border-radius: 0;
  }
  &.mnt-btn-red {
    background-color: $monza;
    font-weight: $font-weight-normal;
    font-size: $font-size-small;
  }
}
