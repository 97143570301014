.mnt-Feed {
  width: 100%;
  position: relative;
  z-index: 1;

  .mnt-Post-hash:before {
    display: block;
    content: ' ';
    margin-top: -48px;
    height: 48px;
    visibility: hidden;
  }
}

.mnt-endOfFeed {
  width: 100%;
  padding: 1rem 0 3rem 0;
  text-align: center;

  @include media-breakpoint-up(md) {
    padding-bottom: 0;
  }

  .mnt-logoMnt {
    display: inline-flex;

    svg {
      fill: $black;
    }
  }

  .mnt-darkTheme & {
    svg {
      fill: $white;
    }
  }
}

.mnt-fb-post {
  text-align: center;
}

.fslightbox-absoluted.fslightbox-flex-centered > div {
  width: 100%;
  height: 100%;
}

.fslightbox-absoluted.fslightbox-flex-centered {
  width: 95%; // -2.5 * 2
  height: 95%; // -2.5 * 2
  margin: 2.5%;
  overflow: hidden;
  @include media-breakpoint-down(md) {
    width: 100%;
    height: 100%;
    margin: 0;
  }
}

.fslightboxs {
  width: 100% !important;
  height: 100% !important;
  position: relative;
  cursor: default;
}

.fslightboxs img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
