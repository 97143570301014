%posts-params {
  font-size: $font-size-base;
  line-height: $line-height-17;
  padding: 16px;
  border-bottom: 1px solid $lightwhiteborder;
  transition: background-color 0.3s ease 0s;
  &:last-child {
    border-bottom: none;
    padding-bottom: 7px;
  }
  &:hover {
    background-color: rgba($shark, 0.7);
  }
}
.mnt-Aside {
  display: none;
  width: 100%;
  border: 1px solid $lightWhite;
  border-top: none;
  position: relative;
  @include media-breakpoint-up(xl) {
    display: block;
    margin-right: 20px;
  }
  .mnt-AsideHeader {
    top: 0;
    z-index: 1001;
    position: sticky;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $lightWhite;
    border-bottom: 1px solid $lightWhite;
  }
  h3 {
    font-size: $font-size-medium;
    line-height: $line-height-31;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    svg {
      fill: $radicalred;
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
  .mnt-HeaderLogo {
    width: 34px;
    height: 34px;
    padding: 7px;
    background-color: $monza;
    border-radius: 2.16px;
    svg {
      fill: $white;
      width: 20px;
      height: 20px;
    }
  }
  .mnt-TrendingPost {
    display: grid;
    grid-template-columns: 200px auto;
    grid-column-gap: 11px;
    border-bottom: 1px solid $lightWhite;
    color: $black;

    @extend %posts-params;
    &:hover {
      //text-decoration: underline;
      background-color: inherit;
    }
    .mnt-PostImage {
      display: block;
      position: relative;
      height: 83px;
      width: 100%;
      overflow: hidden;
      img {
        border-radius: 3px;
        max-width: 100%;
        height: auto;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        aspect-ratio: attr(width) / attr(height);
      }
    }
  }
  .mnt-CurrentPost {
    display: block;
    @extend %posts-params;
    border-bottom: 1px solid $lightWhite;
    &:hover {
      background-color: inherit;
    }
    .mnt-time {
      display: block;
      font-family: $font-family-secondary;
      font-weight: $font-weight-medium;
      color: $border_light;
      font-size: $font-size-small;
      line-height: $line-height-20;
      margin-bottom: 5px;
    }
  }
  .mnt-postBlock {
    height: #{'calc(50vh - 56px)'};
    width: 100%;
    position: relative;

    &:first-child {
      margin-bottom: 10px;
    }
    &::after {
      content: '';
      position: absolute;
      height: 25%;
      width: 100%;
      background: linear-gradient(transparent, $white);
      pointer-events: none;
    }
  }
  .mnt-CurrentPosts {
    position: relative;
    height: #{'calc(100% - 66px)'};
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: thin;
    scrollbar-color: $lightWhite transparent;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba($white, 0);
      border-radius: 2px;
    }
    &:hover {
      scrollbar-width: thin;
      scrollbar-color: $lightWhite transparent;
      -ms-overflow-style: initial;
      &::-webkit-scrollbar-thumb {
        background-color: rgba($white, 0.5);
      }
    }
    a {
      color: $black;
      //&:hover {
      //  text-decoration: underline;
      //}
      //.mnt-time {
      //  text-decoration: none !important;
      //}
    }
  }
}
