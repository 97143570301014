.mnt-Toolbar {
  height: 100%;
  @include media-breakpoint-up(md) {
    background-color: #000000;
  }
}
.mnt-ToolbarDesk {
  display: none;
  @include media-breakpoint-up(md) {
    display: grid;
    height: 100%;
    grid-template-columns: 340px minmax(55px, 340px) minmax(376px, 764px);
    justify-content: space-between;
    align-content: center;
    column-gap: 0;
  }
  @include media-breakpoint-up(xxl) {
    grid-template-columns: 510px minmax(376px, 614px);
    column-gap: calc((100vw - 1920px + 70px) / 2);
  }
}
.mnt-ToolbarMob {
  display: grid;
  grid-template-columns: 85% 15%;
  height: 100%;
  background-color: #000000;
  @include media-breakpoint-up(md) {
    display: none;
  }
}
.mnt-LeftBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mnt-logoMnt {
  padding: 0;
  display: flex;
  align-items: flex-end;
  @include media-breakpoint-up(md) {
    &.mnt-p-0 .mnt-logoTitle {
      margin-top: 0 !important;
    }
  }
  .mnt-logo {
    position: relative;
    width: 47px;
    height: 47px;
  }
  svg {
    width: 47px;
    height: 47px;
    fill: $white_for_dark_mode;
    position: relative;
    top: 0;
    @include media-breakpoint-up(md) {
      fill: $white;
    }
  }
  .mnt-logoTitle {
    font-size: 18px;
    font-family: $font-family-primary;
    font-weight: 500;
    align-self: center;
    margin-left: 16px;
    color: $white;
    small {
      font-weight: $font-weight-light;
    }
    @include media-breakpoint-up(md) {
      color: $white;
      font-size: 24px;
    }
  }
}
.mnt-logoN {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $black;
  height: 100%;
  font-size: 25px;
  width: 190px;
  .mnt & {
    color: $white;
    letter-spacing: 0.05em;
  }
  svg {
    margin-left: 11px;
    width: 40px;
    height: 40px;
    fill: $white_for_dark_mode;
  }
  &.mnt-logo-header {
    background-color: $monza;
    svg {
      fill: $white;
    }
  }
}

.mnt-logoN-cz {
  line-height: 30px;
  font-size: 24px;
  svg {
    margin-left: 7px;
    width: 30px;
    height: 30px;
    fill: $monza;
    background-color: $white;
    padding: 3px;
  }
}

.mnt-backLink {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.94118rem 0 0;
  svg {
    position: relative;
    margin-right: 10px;
    top: -1px;
    width: 15px;
    height: 15px;
    fill: $satan;
  }
  span {
    font-family: $font-family-secondary;
    font-weight: $font-weight-medium;
    font-size: 16px;
    color: $satan;
  }
}

.mnt-ToolbarActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 40px;
  height: 100%;
}
.mnt-CenterBar {
  display: flex;
  position: static;
  justify-content: center;
  width: auto;
  @include media-breakpoint-up(md) {
    justify-content: flex-start;
  }
  @include media-breakpoint-up(xxl) {
    position: absolute;
    left: calc(50% - 20px);
    transform: translateX(-45%);
    height: 100%;
    width: 724px;
  }
  .mnt-widgetTopbar & {
    justify-content: center;
  }
}
.mnt-RightBar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.mnt-hamburgerMenu {
  padding-right: 16px;
  svg {
    width: 22px;
    height: 22px;
    fill: $white;
    &.hamburger {
      fill: $monza !important;
    }
  }
}

.mnt-login-cta,
.mnt-my-account-cta {
  color: $white;
  padding: 7px 16px;
  margin-left: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  border: 1px solid $border_light;
  border-radius: 50px;
  &:hover {
    border-color: $border_dark;
    color: #f6efed;
    background: $satan;
  }
  &:focus {
    background-color: $read_more_for_dark;
    color: $dark_black;
  }
  svg {
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }
}

.mnt-my-account-cta svg {
  fill: $chateaugreen;
}
.mnt-login-cta {
  min-width: 114px;
}

.mnt-login-cta svg {
  fill: $radicalred;
}

.mnt-p-0 {
  padding: 0;
}
