a {
  .mnt-Minuta &,
  .mnt & {
    //color: $monza;
    text-decoration: none;
  }
}
p {
  .mnt-Minuta & {
    font-size: $font-size-normal;
    line-height: $line-height-22;
    font-weight: $font-weight-light;
    @include media-breakpoint-up(md) {
      font-size: 19px;
      line-height: $line-height-24;
    }
  }
}
.mnt-Minuta {
  .mnt-sansMd {
    font-family: $font-family-secondary;
    font-weight: $font-weight-bold;
    color: $border_light;
    letter-spacing: 0.5px;
  }
}
