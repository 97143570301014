.mnt-FeedSticky article.mnt-articleOnlyTags .mnt-tags {
  align-items: flex-start;
  margin-bottom: 0;
}

.mnt-tags {
  display: inline-grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 2.5px;
  max-width: 100%;
  overflow-x: scroll;
  align-items: center;
  justify-content: flex-start;
  font-family: $font-family-secondary;

  scrollbar-width: none;
  padding: 8px 0;

  .mnt-Posts & {
    align-items: flex-start;
  }

  &.mnt-fade-end::after {
    content: '';
    position: sticky;
    right: -1px;
    bottom: 0;
    width: 65px;
    height: 24px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), $alabaster);
    pointer-events: none;
  }

  &::-webkit-scrollbar {
    height: 0;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  .mnt-FeedSticky &.mnt-fade-end {
    &::after {
      background: linear-gradient(to right, rgba($wildsand, 0), $wildsand);
    }
  }

  .mnt-Menu & {
    display: block;
    padding: 14px 20px 10px;
    border-bottom: 1px solid $lightWhite;
    @include media-breakpoint-up(sm) {
      display: block;
    }

    .mnt-tag {
      margin: 0 4px 4px 0;
    }
  }

  .mnt-tag {
    white-space: nowrap;
    padding: 8px 12px;
    height: 24px;
    background-color: $white;
    border: 1px solid #c2bbb9;
    border-radius: 21px;
    font-family: $font-family-secondary;
    font-weight: 700;
    letter-spacing: -0.1px;
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    font-size: $font-size-small;
    transition: color 0.25s ease 0s;
    color: $satan;

    &:hover {
      border-color: $border_dark;
    }
    &.mnt-highlight {
      background-color: $highlight;
      //text-decoration: underline;
      //text-decoration-color: $link_light_underline;
    }
  }

  &.mnt-space-btw {
    justify-content: space-between;
    align-items: center;

    .mnt-live {
      margin-bottom: 0;
    }
  }
  .mnt-btn.mnt-btn-small {
    padding: 8px 12px;
    height: 24px;
    margin-top: 0;
    border: 1px solid #c2bbb9;
    text-transform: uppercase;
    color: $satan;
    font-weight: 700;
    font-size: 12px;
    svg {
      fill: $satan;
    }
  }
}

.mnt-tags-wrapper {
  padding: 8px 0;
  position: relative;
  .mnt-tags {
    margin-bottom: 0;
  }
  .mnt-tag {
    &:first-child {
      margin-left: 16px;
    }
    &:last-child {
      margin-right: 16px;
    }
  }
}

.mnt-tags-article-wrapper {
  margin-bottom: 16px;
  .mnt-tags {
    margin-bottom: 0;
  }
}

.mnt-FeedArticleImportant {
  .mnt-tag {
    background-color: $highlight;
  }
  .mnt-fade-end::after {
    background: linear-gradient(to right, rgba(255, 255, 255, 0), $highlight);
  }
}

@keyframes blik {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mnt-live {
  display: flex;
  align-items: center;
  font-family: $font-family-secondary;
  font-size: $font-size-small;
  font-weight: $font-weight-normal;
  line-height: 12px;
  text-transform: uppercase;
  color: $white;
  background-color: $black;
  padding: 4px 6px;
  border-radius: 3px;
  align-self: center;

  &::before {
    content: '';
    margin-right: 5px;
    width: 8px;
    height: 8px;
    background-color: $radicalred;
    border-radius: 50%;
    position: relative;
    animation: blik 0.5s linear 0s infinite alternate;
  }
}

.mnt-carret {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  height: 100%;
  width: 30px;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    width: 110px;
    height: 31px;
    pointer-events: none;
  }

  svg {
    fill: $satan;
    width: 8px;
    height: 12px;
  }

  button {
    z-index: 1;
    width: 100%;
    height: 100%;
  }
}

.mnt-carret-right {
  right: 0;

  &::after {
    right: 0;
    background: linear-gradient(to right, rgba($white, 0), $white);
  }
}

.mnt-carret-left {
  left: 0;

  &::after {
    left: 0;
    background: linear-gradient(to left, rgba($white, 0), $white);
  }
}
