.mnt-shareLinks {
  padding: 14.5px;
  border: 1px solid $gallery;
  border-radius: 0 0 3px 3px;
  background-color: $white;
  position: absolute;
  right: 21px;
  top: 35px;
  z-index: 1;
  .mnt-claerShareLinks {
    position: absolute;
    right: 15px;
    top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    svg {
      width: 10px;
      height: 10px;
    }
    &:hover {
      svg {
        fill: $monza;
      }
    }
  }
  .mnt-shares {
    display: grid;
    grid-template-rows: max-content;
    grid-row-gap: 4.5px;
    button,
    a {
      margin: 2px 0;
      border-radius: 3px;
      color: $black;
      font-size: $font-size-small;
      font-weight: 600;
      letter-spacing: 0.28px;
      line-height: $line-height-18;
      text-transform: uppercase;
      font-family: $font-family-secondary;
      display: flex;
      align-items: center;
      background-color: $white;
      transition: background-color 0.25s ease 0s;
      &:hover {
        background-color: $lightWhite;
        color: $black;
        svg {
          fill: $black;
        }
      }
    }
    svg {
      transition: fill 0.25s ease 0s;
      margin-right: 8px;
      width: 16px;
      height: 16px;
      fill: $black;
      &.mnt-facebook {
        fill: $black;
      }
      &.mnt-messenger {
        fill: $black;
      }
      &.mnt-twitter {
        fill: $black;
      }
      &.mnt-whatsapp {
        fill: $black;
      }
      &.mnt-gmail {
        fill: $black;
      }
    }
  }
}
