%readMoreBtn {
  width: 100%;
  padding: 16px 40px;
  margin: 20px auto;
  @include media-breakpoint-up(sm) {
    width: auto;
  }
}

.mnt-FeedArticle.mnt-FeedArticleImportant {
  color: $read_more;
  &.mnt-FeedArticleSeen {
    .mnt-toolbar {
      .mnt-time {
        &::after {
          background-color: $highlighted_link_on_light;
        }
      }
    }
  }
  .mnt-article {
    & > p {
      a {
        color: $highlighted_link_on_light;
        &:active,
        &:hover,
        &:focus {
          color: $highlighted_link_on_light !important;
          background-color: $link_for_dark_mode;
        }

        &:visited {
          color: $highlighted_visited_link_on_light;
        }

        &[data-link='ext']:hover::after {
          background-color: $highlighted_link_on_light !important;
        }
        &[data-link='ext']::after {
          background-color: $highlighted_link_on_light;
        }
      }
    }
  }
  .mnt-toolbar {
    .mnt-time {
      color: $read_more;
    }
  }
}

.mnt-FeedArticle {
  padding: 0 24px 0 16px;
  border-bottom: 1px solid $lightWhite;
  position: relative;
  z-index: auto;

  .mnt-article-title {
    font-weight: $font-weight-medium;
    font-size: 19px;
    line-height: 24px;
    padding-bottom: 15px;
  }

  .mnt-Important-cz {
    span::after {
      content: '!';
      position: relative;
      right: -7px;
      display: inline-block;
      width: 16px;
      height: 16px;
      text-align: center;
      line-height: 16px;
      font-size: 12px;
      color: $white;
      background: #bf2134;
    }
  }

  .mnt-article {
    margin-top: -4px;
    //margin-bottom: 24px;

    & > article figure {
      margin-bottom: 1rem;
    }

    & > p:first-child {
      a {
        text-decoration: underline;
        text-decoration-color: $link_light_underline;
        font-weight: $font-weight-medium;
      }
    }

    & > article,
    & > p {
      margin-bottom: 15px;
      font-family: $font-family-primary;

      a {
        color: $monza;
        font-size: inherit;
        font-weight: $font-weight-light;
        text-decoration: underline;
        text-decoration-color: $link_light_underline;

        &:active,
        &:hover,
        &:focus {
          color: $monza !important;
          background-color: $link_hover_on_light;
        }

        &:visited {
          color: $link_visited;
        }

        &[data-link='ext']:hover::after,
        &[data-link='ext']:focus::after {
          background-color: $link_hover !important;
        }

        &[data-link='ext']:visited::after {
          background-color: $link_visited;
        }

        &[data-link='ext']::after {
          -webkit-mask: url('../../assets/sk/svg/extermal-feed-link.svg') no-repeat 50% 50%;
          mask: url('../../assets/sk/svg/extermal-feed-link.svg') no-repeat 50% 50%;
          -webkit-mask-size: cover;
          mask-size: cover;
          background-color: $monza;
          display: inline-block;
          content: '';
          height: 10px;
          width: 10px;
          margin-left: 4px;
        }
      }

      strong {
        font-size: inherit;
        font-weight: $font-weight-medium;
      }
    }

    ul {
      margin-bottom: 15px;
      padding: 0;

      li {
        line-height: 24px;
        font-family: $font-family-primary;
        font-size: $font-size-normal;
        font-weight: 300;
        margin-left: 20px;
        position: relative;
        list-style: square;
        list-style-position: outside;

        &::marker {
          color: $monza;
        }

        @include media-breakpoint-up(md) {
          font-size: 19px;
        }

        + li {
          margin-top: 3px;
          @include media-breakpoint-up(md) {
            margin-top: 5px;
          }
        }

        a {
          color: $monza;
          text-decoration: underline;
          text-decoration-color: $link_light_underline;
          &:hover,
          &:focus {
            background-color: $link_hover_on_light;
          }
          &:visited {
            color: $link_visited;
          }
        }
      }
    }
  }

  .mnt-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    text-transform: uppercase;

    & > a {
      margin-top: -4px;
      &:hover .mnt-time {
        color: rgba($satan, 0.8);
      }
    }

    .mnt-time {
      text-transform: lowercase;
      font-family: $font-family-secondary;
      font-weight: 600;
      transition: color 0.25s ease 0s;
      font-size: $font-size-small;
      color: $black;
    }

    .mnt-adminEdit {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $border_light;
      width: 24px;
      height: 24px;
      border-radius: 12px;
    }

    .mnt-Icons {
      display: flex;
      gap: 2px;
      .mnt-External {
        position: relative;
        border-radius: 50%;
        border: 1px solid $border_light;
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 0.3s ease 0s;
        &:hover {
          background-color: $satan;
          svg {
            fill: #f6efed;
          }
        }
        &:focus {
          border-color: #c2bbb9;
          background-color: #f6efed;
          svg {
            fill: $satan;
          }
        }
        svg {
          width: 12px;
          height: 12px;
          fill: $satan;
          transition: fill 0.3s ease 0s;
        }
      }
    }
  }

  .tile {
    background-color: $highlight;
    padding: 15px 16px;
    margin-left: -20px;
    margin-right: -20px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    border-top: 1px solid #e2dbd9;
    border-bottom: 1px solid #e2dbd9;

    .tile_figure {
      width: 120px;
      height: 100%;
      margin: 0;
      padding: 1.5rem 0 0 0;
      display: block;
      flex: 0 0 120px;
    }

    .tile_title {
      width: 100%;
      font-size: 19px;
      line-height: 22px;
      padding-right: 8px;
      a {
        text-decoration: none;
        font-weight: $font-weight-medium;
        &:hover {
          color: #292222 !important;
          background-color: transparent;
        }
      }
    }

    a {
      color: #292222 !important;
    }

    .tile_call {
      display: inline-flex;
      font-family: $font-family-secondary;
      font-size: 12px;
      line-height: 14px;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      color: rgba($black, 0.5);
      margin-bottom: 9px;
    }
  }

  figure {
    padding: 0 0 15px;
    margin: 0 -20px 0 -20px;
    max-width: 100vw;
    width: 100vw;
    position: relative;
    @include media-breakpoint-up(sm) {
      width: 100%;
      max-width: 100%;
      margin: 0;
    }

    .mnt-zoom {
      position: absolute;
      top: 0;
      right: 0;
      padding: 15px;

      svg {
        width: 15px;
        height: 15px;
        fill: rgba($white, 1);
        filter: drop-shadow(0 0 3px $black);
      }
    }

    & + .mnt-btn {
      @extend %readMoreBtn;
    }

    img {
      max-width: 100%;
      height: auto;
      aspect-ratio: attr(widht) / attr(height);
    }

    figcaption {
      font-family: $font-family-secondary;
      font-size: $font-size-sm;
      line-height: $line-height-20;
      font-weight: 400;
      padding: 2px 20px 0;
      color: $satan;
      @include media-breakpoint-up(sm) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    & + .mnt-tags {
      padding-top: 0;
      //margin-bottom: 20px;
    }
  }

  .video-responsive {
    position: relative;
    margin-top: 17px;
    margin-bottom: 15px;
    width: 100%;
    overflow: hidden;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    &.youtube {
      padding-bottom: 56.25%; // 16:9
      height: 0;
    }
    &.instagram {
      margin: 0 auto;
      iframe {
        height: revert-layer;
        position: relative;
      }
    }

    & + .mnt-btn {
      @extend %readMoreBtn;
    }
  }

  .mnt-blankLink {
    display: flex;
    align-items: center;
    //margin-bottom: 24px;

    & + .mnt-btn {
      @extend %readMoreBtn;
    }
  }
}

.mnt-FeedArticle.mnt-FeedArticleImportant .mnt-article ul li {
  &::marker {
    color: $highlighted_link_on_light;
  }
}

.mnt-FeedArticleSeen {
  .mnt-toolbar {
    .mnt-time {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        height: 4px;
        width: 4px;
        background-color: $monza;
        border-radius: 5px;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 4px;
      }
    }
  }
}

.mnt-FeedArticleImportant,
.mnt-articleLive {
  background-color: $highlight;
  .mnt-article.mnt-Important-sk:after,
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 22px 22px 0;
    border-color: transparent $monza transparent transparent;
    right: 0;
    top: 0;
    position: absolute;
  }
}
