%linkProperties {
  color: $black;
  font-size: $font-size-medium;
  line-height: $line-height-26;
  padding: 7px 10px;
  transition: background-color 0.25s ease 0s;
  display: block;
  margin: 0 -10px 5px -10px;
  border-radius: 3px;

  &:hover {
    background-color: $wildsand;
  }
}

%toolbarButtons {
  width: 40px;
  height: 40px;
  @include media-breakpoint-up(md) {
    display: none;
  }

  svg {
    fill: $monza;
    width: 15px;
    height: 15px;
  }
}

.mnt-Menu {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  background-color: $white;
  height: 100%;
  max-width: 100%;
  padding: 25px 0 100px 0;
  margin-top: 0;
  border: 1px solid $lightWhite;
  @include media-breakpoint-up(sm) {
    border-radius: 3px;
    padding: 25px 0 0 0;
    margin-top: 30px;
    margin-left: 20px;
  }

  .mnt-NavOverlay {
    padding: 10px 0 10px 0;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 170px);
    @include media-breakpoint-down(sm) {
      height: 70vh !important;
      max-height: calc(100vh - 170px) !important;
    }
    @include media-breakpoint-up(sm) {
      padding: 0 0 0 0;
      height: auto;

      scrollbar-width: thin;
      scrollbar-color: transparent transparent;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba($black, 0);
        border-radius: 2px;
      }
      &:hover {
        scrollbar-width: thin;
        scrollbar-color: rgba($black, 0.5) transparent;
        -ms-overflow-style: initial;

        &::-webkit-scrollbar-thumb {
          background-color: rgba($black, 0.5);
        }
      }
    }

    .mnt-darkTheme & {
      &:hover {
        scrollbar-width: thin;
        scrollbar-color: rgba($white, 0.5) transparent;
        -ms-overflow-style: initial;

        &::-webkit-scrollbar-thumb {
          background-color: rgba($white, 0.5);
        }
      }
    }
  }

  h5 {
    text-transform: uppercase;
    font-size: $font-size-small;
    line-height: $line-height-18;
    color: rgba($black, 0.4);
    padding: 0 20px;

    & + .mnt-sideMenu {
      padding: 5px 20px 0 20px;
    }
    & + .mnt-sideMenu.mnt-settings {
      padding-top: 0;
    }
  }

  .mnt-LiveLink {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0;
    padding: 12px 20px 15px;
    grid-gap: 12px;

    a {
      line-height: $line-height-22;
      color: $black;
      transition: color 0.25s ease 0s;

      &:hover {
        color: $monza;
      }
    }

    .#{$namespace}-live {
      margin-bottom: 0;
    }
  }

  .mnt-LiveLinks {
    padding-top: 15px;
    border-bottom: 1px solid $gallery;
  }

  .open & {
    width: #{'calc(100vw - 65px)'};
  }
}

.mnt-menuToolbar {
  max-width: 100%;
  margin-bottom: 20px;
  @include media-breakpoint-down(sm) {
    display: grid;
    grid-template-columns: #{'calc(100% - 100px)'} 40px 40px;
    justify-content: space-between;
  }

  .mnt-user {
    @extend %toolbarButtons;
  }

  .mnt-user-logged-in svg {
    fill: $chateaugreen;
  }

  .mnt-user-logged-out svg {
    fill: $radicalred;
  }

  .mnt-close {
    @extend %toolbarButtons;
  }
}

.mnt-MenuSearchBox {
  padding: 0 0 0 20px;
  position: relative;

  input[type='text'] {
    padding-right: 40px;
    height: 40px;
    border-radius: 80px;
    background: transparent;
    border: 1px solid $lightWhite;
    font-size: 14px;

    &::placeholder {
      font-family: $font-family-secondary;
      color: $satan;
      font-weight: $font-weight-bold;
      font-size: 12px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }
  }

  @include media-breakpoint-up(sm) {
    padding: 0 20px 0 20px;
  }

  button {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
    @include media-breakpoint-up(sm) {
      right: 35px;
    }

    svg {
      fill: rgba($black, 0.5);
      width: 16px;
      height: 16px;
    }
  }
}

.mnt-sideNav {
  position: relative;
  padding: 15px 0 0;
}

.mnt-settings {
  h5 {
    margin-bottom: 25px;
  }

  .mnt-newsletter {
    @extend %linkProperties;

    svg {
      fill: $monza;
      width: 16px;
      height: 16px;
      margin-right: 13px;
    }
  }
}

.mnt-sideMenu {
  &.top {
    padding: 0 20px;
  }

  a {
    @extend %linkProperties;
    margin-bottom: 0;
  }

  &.mnt-bordered_bottom {
    border-bottom: 1px solid $gallery;
  }

  .mnt-IconLink {
    display: flex;
    align-items: center;
    @extend %linkProperties;

    &.mnt-bordered {
      padding-bottom: 22px;
      border-bottom: 1px solid $lightWhite;
    }

    svg {
      margin-right: 13px;
      fill: $shiraz;
      width: 16px;
      height: 16px;
    }

    &.active {
      //background-color: $wildsand;
      color: $monza;
    }
  }

  .mnt-NavLink {
    &.active {
      //background-color: $wildsand;
      color: $monza;
    }
  }
}
